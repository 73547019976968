.text__scrollable{
    height: 15rem;
    max-height: 15rem;
    overflow-y: auto;
    color: white;
}
.media__video
{
    max-width: 80rem;
    height: auto;
}

.media__img{
    max-width: 45rem;
}
/* to remove the img from playlist */
.album-cover-wrapper {
    display: none !important;
}
/* PieChart */
tspan{
    font-size: 1rem !important;
    color: black !important;
}
.gauge__container{
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}
.pieChart{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.charts__container{
    width: 80%;
    height: 50vw;
}
.legend__item, .legend{
    display: flex;
    gap: 0.5rem;
}
.legend{
    flex-direction: column;
}
.legend__color{
    display: block;
    height: 0.8rem;
    width: 0.8rem;
}
.legend__label{
    font-size: 0.8rem;
    color: white;
}

.echarts-for-react{
    height: 50vh !important;
}