.inventoryTile,
.iframe {
    margin: 2rem;
    flex-grow: 1;
}
.inventoryTile {
    padding-bottom: 2rem;
    position: relative;
}
.iframe {
    height: 38rem;
}
.list {
    position:relative;
    margin: 2rem;
    height: 30rem;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: start;
    z-index: 0;

    /*
    pour firefox = si je les mets ça enlève la suite en bas donc à voir ? 
    
       scrollbar-width: thin; 
       scrollbar-color: #ffffff #adccfb; 
 */
}

.list::-webkit-scrollbar, .text__scrollable::-webkit-scrollbar {
    width: 010px; /* width of the entire scrollbar */
}

.list::-webkit-scrollbar-track, .text__scrollable::-webkit-scrollbar-track {
    background: #adccfb; /* color of the tracking area */
    border-radius: 1.25rem; /* roundness of the scroll thumb */
}

.list::-webkit-scrollbar-thumb, .text__scrollable::-webkit-scrollbar-thumb {
    background-color: #ffffff; /* color of the scroll thumb */
    border-radius: 1.25rem; /* roundness of the scroll thumb */
}

.inventoryTile::after {
    content: '';
    position:absolute;
    bottom: 6.75rem;
    width: calc(100% - 5.625rem);
    left: 2rem;
    height: 1rem;
    /* background-color: red; */
    background: linear-gradient(#ffffff00, #ffffff);
}

.inventory {
}

.listItem {
    background-color: #ffffff;
    border-radius: 0.75rem;
    margin-right: 1rem;
}
.listItem__title {
    background-color: #e1eff6;
    padding: 0.75rem;
    font-weight: 700;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}
.listItem__topic {
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.listItem__topic:active {
    background-color: #a6dfff;
}
.listItem__topic:active > .topic__ccImg {
    background-image: url(../../assets/img/active-copy.png);
}
.listItem__focus{
    background-color: #a6dfff;
}
.border {
    border-bottom: 0.1px solid #000;
}
.topic__url {
    font-style: italic;
    font-size: 0.9rem;
}
.topic__ccImg {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    background-image: url(../../assets/img/copy.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.topic__ccImg__focus {
    background-image: url(../../assets/img/active-copy.png);
}

/** popup style */
.popup {
    background-color: #000000b7;
    color: white;
    font-style: italic;
    width: max-content;
    z-index: 999;
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
    position: absolute;
    left: calc(0% - 170%);
    bottom: 1.5rem;
    border-radius: 0.75rem;
    opacity: 1;
    animation: fadeOut 0.8s forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1; 
    }
    80% {
        opacity: 1; 
    }
    100%{
        opacity: 0;
    }
}
/* on click just remove the popup */