.f-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.home__container {
    height: 100vh;
}
.home,
.tile__form {
    flex-direction: column;
}
.home {
    gap: 2.5rem;
}
.home__logo {
    height: 20rem;
}
.homeTile {
    padding: 2.5rem 5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.homeTile__invalid{
    padding: 2.5rem 3rem 2rem;
}
.homeTile__title {
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--white);
}
.invalidSheet{
    font-style: italic;
    color: red;
    margin-top: 0.5rem;
    height: 1rem;
}
.invalidSheet__img{
    height: 2rem;
    width: 2rem;
}
.homeTile__form {
}
.tile__formItem {
    padding: 1.5rem 0 2.5rem;
    display: flex;
    align-items: center;
    height: 2rem;
    gap: 0.75rem;
    width: 23rem;
}
.tile__input {
    flex-grow: 1;
    border: none;
    border-radius: 0.75rem;
    padding: 0.75rem 1rem;
    box-shadow: var(--input-shadow);
}
.tile__input::placeholder {
    font-style: italic;
    font-size: 0.75rem;
}
.upload {
}
.upload__input {
    display: none; 
}

.upload__img {
    transform: scale(0.9);
    cursor: pointer;
}

.file-input__mess{
    display: block;
    color: white;
}
