@import "./reset.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
    --font: "Inter", sans-serif;
    --white: #fefefe;
    --tile-gradient: linear-gradient(#6bbbf8 0%, #699af7 100%);
    --tile-light: linear-gradient(to right, #91d6f7, #91d6f7);

    --input-shadow: 0.5 1 1px rgba(0, 0, 0, 0.25);
    --btn-blue: linear-gradient(#1a42bc, #1a42bc);
    --btn-light: linear-gradient(to right, #365cc7, #365cc7);
}

main,
a,
h1,
h2,
h3,
input,
label,
button,
li,
span,
p {
    font-family: var(--font);
}
a{
    text-decoration: none;
}

.tile {
    border-radius: 1.25rem;
    background: var(--tile-gradient) padding-box, var(--tile-light) border-box;
    border-top: 4px solid transparent;
}
.main__btn {
    background: var(--btn-blue);
    border: none;
    color: white;
    font-weight: 700;
    font-size: 0.9rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    background: var(--btn-blue) padding-box, var(--btn-light) border-box;
    border-top: 3px solid transparent;
    cursor: pointer;
}
.display__container{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.display__title{
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 1rem;
    color: white;
}

/*
    à changer !!     ᕕ( ᐛ )ᕗ
*/
.tile__background{
    border-radius: 1.25rem;
    background: var(--tile-gradient) padding-box, var(--tile-light) border-box;
    border-top: 4px solid transparent;
}
.text__scrollable{
    white-space: pre-wrap;
    text-wrap: balance;
    line-height: 125%;
}
/*.text__scrollable:nth-child(even){
    background-color: #365cc7;
    background: 
    linear-gradient(to bottom, #ffffff73,#ffffff73 50%,#b0e2ff46 50%, #b0e2ff46) 
    left/
    100% 50%
    repeat;
}*/